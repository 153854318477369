import React from "react";
import { Link } from "react-router-dom";

const HomeBanner = () => {
  return (
    <div
      style={{
        background:
          "linear-gradient(to bottom, var(--primary-color), var(--body-color))",
      }}
      className="hero-section pt-lg-5 p-0"
    >
      <div className="container pt-5">
        <div className="row">
          <div className="col">
            <div className="text-center">
              <h2 className="text-white">
                Our touch will make your hair look at its best
              </h2>
              <Link
                href="#lnk-1"
                className="btn btn-tra-custom hover--black my-4"
              >
                Book Now
              </Link>
            </div>
          </div>
        </div>

        <div>
          <img
            className="img-fluid"
            src="images/banner-img/slide-11.webp"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default HomeBanner;
