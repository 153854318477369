import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { Autoplay, Pagination, Navigation } from "swiper/modules";

const images = [
  { img: "images/gallery/gallery3.webp" },
  { img: "images/gallery/gallery2.webp" },
  { img: "images/gallery/gallery3.webp" },
  { img: "images/gallery/gallery2.webp" },
  { img: "images/gallery/gallery3.webp" },
];

const TrendingSection = () => {
  return (
    <div className="px-5 py-default">
      <div class="row justify-content-center">
        <div class="col-md-10 col-lg-8">
          <div class="section-title text-center mb-3">
            <h2>TRENDING STYLE</h2>
          </div>
        </div>
      </div>
      <Swiper
        slidesPerView={1}
        spaceBetween={15}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        loop={true}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          1281: {
            slidesPerView: 4,
          },
          1024: {
            slidesPerView: 3,
          },
          768: {
            slidesPerView: 2,
          },
          320: {
            slidesPerView: 1,
          },
        }}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        <div>
          {images.map((img, index) => (
            <SwiperSlide className="mb-5 p-3 rounded-3" key={index}>
              <img className="w-100 rounded-3" src={img.img} alt="" />
              <div className="text-center pt-3">
                <h5>Trending Img</h5>
              </div>
            </SwiperSlide>
          ))}
        </div>
      </Swiper>
    </div>
  );
};

export default TrendingSection;
