import React from "react";
import ReactDOM from "react-dom";
import { RouterProvider } from "react-router-dom";
import { router } from "./Routes/Routers";
import "../src/assets/CSS/menu.css";
import "../src/assets/CSS/flaticon.css";
import "../src/assets/CSS/bootstrap.min.css";
import "../src/assets/CSS/style.css";
import "../src/assets/CSS/header.css";
import "../src/assets/CSS/custom.css";
import "../src/assets/CSS/responsive.css";

ReactDOM.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
  document.getElementById("root")
);
